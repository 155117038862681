import React from 'react';

import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { Location } from '@reach/router';

import Breadcrumbs from './Breadcrumbs';
import PrimaryLayout from './PrimaryLayout';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

export function PrimaryInnerPage({
    children,
    title,
    metadata,
    description,
    pageType,
    image,
    site,
    environment,
}) {
    return (
        <PrimaryLayout
            site={site}
            title={title}
            description={description}
            image={image}
            metadata={metadata}
            environment={environment}
            pageType={pageType}
        >
            <main id="main" role="main">
                <div className={pageType ? pageType : 'single-page'}>
                    {children}
                </div>
            </main>
        </PrimaryLayout>
    );
}

export const IntroHolder = ({
    children,
    title,
    subtitle,
    showSubtitleBefore,
    picture,
    breadcrumbs,
}) => (
    <div className="intro-holder">
        <Container>
            <Location>
                {({ location }) => {
                    if (
                        !location.pathname.includes('/haus-der-andacht/') &&
                        breadcrumbs
                    ) {
                        return (
                            <Breadcrumbs
                                currentItem={breadcrumbs['current-item']}
                                parent={breadcrumbs['parent']}
                                grandparent={breadcrumbs['grandparent']}
                                greatgrandparent={
                                    breadcrumbs['greatgrandparent']
                                }
                            />
                        );
                    }
                }}
            </Location>
            {!showSubtitleBefore ? (
                <>
                    <h1 className="text-5xl">{title}</h1>
                    {subtitle ? (
                        <strong className="sub-title">
                            <p>{subtitle}</p>
                        </strong>
                    ) : (
                        ''
                    )}
                </>
            ) : (
                <>
                    {subtitle ? (
                        <strong className="sub-title">
                            <p>{subtitle}</p>
                        </strong>
                    ) : (
                        ''
                    )}
                    <h1 className="text-5xl">{title}</h1>
                </>
            )}
            <div className="intro-holder__flex-wrapper">
                {picture ? (
                    <>
                        <div className="img-holder">
                            <Img
                                backgroundColor="#f8f6f4"
                                fluid={picture.childImageSharp.fluid}
                            />
                        </div>
                    </>
                ) : null}
                {children}
            </div>
        </Container>
    </div>
);

export const TwoColumns = ({ children }) => (
    <div className="two-columns">
        <Container>{children}</Container>
    </div>
);

export const MorePages = ({ children }) => (
    <div className="pages">
        <Container>{children}</Container>
    </div>
);

export const Container = ({ children }) => (
    <div className="container">{children}</div>
);

export const Sidebar = ({ children, showContactWidget }) => (
    <aside id="sidebar">
        {children}
        {showContactWidget === true ? (
            <div className="widget-contact widget">
                <h3>
                    Möchten Sie mit der Bahá’í-Gemeinde vor Ort Kontakt
                    aufnehmen und mehr erfahren?
                </h3>
                <OutboundLink href="https://www.bahai.de/bahai-kennenlernen/">
                    Kontakt zu Bahá'í
                </OutboundLink>
            </div>
        ) : null}
    </aside>
);

export const Reference = ({ children }) => (
    <div className="open-close" id="quellennachweis">
        <p>Quellennachweise</p>
        <div className="slide">
            <ol>{children}</ol>
        </div>
    </div>
);

export const PagePreview = ({ href, imageSource, title, description }) => (
    <div className="page-preview">
        <Link to={href}>
            {imageSource ? (
                <div className="img-holder">
                    <img
                        width="300"
                        height="250"
                        src={imageSource}
                        className="attachment-thumbnail_300x250 size-thumbnail_300x250"
                        alt=""
                    />
                </div>
            ) : (
                ''
            )}
            <div className="text-holder">
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
        </Link>
    </div>
);

export const SidebarWidget = ({ children }) => (
    <div className="widget tablet-hidden">
        <h3>WEITERE THEMEN</h3>
        <ul>{children}</ul>
    </div>
);

export const SidebarWidgetItem = ({ href, children }) => (
    <li>
        <Link to={href}>{children}</Link>
    </li>
);

export const Col = ({ children }) => <div className="col">{children}</div>;

export const PageList = ({ children }) => (
    <div className="pages-list">{children}</div>
);

export const MorePagesInner = ({ children }) => (
    <Col>
        <h2>MEHR ZU DIESEM THEMA</h2>
        <PageList>{children}</PageList>
    </Col>
);

export const Content = ({ children }) => <div id="content">{children}</div>;

export const IntroQuote = ({ children, href }) => (
    <div className="blockquote-text">
        <blockquote>
            <q>
                „{children}”<QuoteReferenceNumber>1</QuoteReferenceNumber>
            </q>
        </blockquote>
        {href && (
            <Link to={href} class="link">
                Zitate zu diesem Thema
                <i class="fa fa-long-arrow-right" aria-hidden="true" />
            </Link>
        )}
    </div>
);

export const CenteredQuote = ({ children, referenceNumber }) => (
    <blockquote>
        <p>
            {children}
            <QuoteReferenceNumber>{referenceNumber}</QuoteReferenceNumber>
        </p>
    </blockquote>
);

export const QuoteReferenceNumber = ({ children }) => (
    <a href="#quellennachweis">
        <sup>{children}</sup>
    </a>
);

export const NormalQuote = ({ children, reference }) => (
    <p className="serif standard-text">
        <blockquote>
            <q>{children}</q>
        </blockquote>
        <cite>{reference}</cite>
        <p className="quoteseparator" />
    </p>
);

export const Amtsblatt = ({ children, volume, year, issue, date, href }) => (
    <div>
        <h3>
            {volume}. Jahrgang – {year} – Ausgabe Nr. {issue} vom {date}
        </h3>
        <p>
            Inhalt: {children} (<a href={href}>PDF herunterladen</a>)
        </p>
    </div>
);
