import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    NormalQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const DieNaturDesMenschenZitate = () => (
    <InnerPage
        title="Zitate: Die Natur des Menschen"
        description="Die Natur des Menschen ist eine mit Vernunft begabte Seele"
    >
        <IntroHolder
            title="Zitate"
            subtitle="Die Natur des Menschen"
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Die Natur des Menschen',
                'current-item': 'Zitate',
            }}
        />
        <TwoColumns>
            <div id="content">
                <NormalQuote reference="Bahá’u’lláh, Verborgene Worte, arabisch 68">
                    O Menschenkinder!
                    <br />
                    Wißt ihr, warum Wir euch alle aus dem gleichen Staub
                    erschufen? Damit sich keiner über den anderen erhebe.
                    Bedenket allzeit in eurem Herzen, wie ihr erschaffen seid.
                    Da Wir euch alle aus dem gleichen Stoff erschufen, ziemt es
                    euch, wie eine Seele zu sein, auf selbem Fuße zu wandeln, in
                    gleicher Weise zu essen und im selben Lande zu wohnen, auf
                    daß aus eurem innersten Wesen durch eure Werke die Zeichen
                    der Einheit und das Wesen der Loslösung offenbar werden.
                    Solches rate Ich euch, o Scharen des Lichts. Achtet wohl auf
                    diesen Rat, damit ihr die heiligen Früchte vom Baume
                    wundersamer Herrlichkeit erlanget.
                </NormalQuote>
                <NormalQuote reference="Bahá’u’lláh, Kitab-i-Iqan, 2:8">
                    Der Mensch, das edelste und vollkommenste aller erschaffenen
                    Wesen…
                </NormalQuote>
                <NormalQuote reference="Bahá’u’lláh, Verborgene Worte, arabisch 13">
                    O Sohn des Geistes!
                    <br />
                    Reich erschuf Ich dich, warum machst du dich selbst arm?
                    Edel erschuf Ich dich, warum erniedrigst du dich selbst? Aus
                    dem Wesen des Wissens gab Ich dir Leben, warum suchst du
                    Erleuchtung bei anderen als Mir? Aus dem Ton der Liebe
                    formte Ich dich, warum befaßt du dich mit anderem? Schaue in
                    dich, daß du Mich in dir findest, mächtig, stark und
                    selbstbestehend.
                </NormalQuote>
                <NormalQuote reference="Bahá’u’lláh, Ährenlese, CXXXII">
                    Wenn der eine, wahre Gott – gepriesen sei Seine Herrlichkeit
                    – sich den Menschen offenbart, verfolgt Er das Ziel, die
                    Edelsteine ans Licht zu bringen, die in den Gesteinsadern
                    ihres wahren inneren Selbstes verborgen liegen.
                </NormalQuote>
                <NormalQuote reference="Bahá’u’lláh, Ährenlese, 159:4">
                    Sprich: Wahre Freiheit besteht in der Unterwerfung des
                    Menschen unter Meine Gebote, so wenig ihr dies auch
                    versteht. Würden die Menschen befolgen, was Wir aus dem
                    Himmel der Offenbarung auf sie herabsandten, so erlangten
                    sie sicherlich vollkommene Freiheit. Glücklich der Mensch,
                    der die Absicht Gottes in allem erfaßt, was Er aus dem
                    Himmel Seines Willens, der alles Erschaffene durchdringt,
                    offenbart! Sprich: Die Freiheit, die euch nützt, ist
                    nirgendwo zu finden außer in vollkommener Dienstbarkeit vor
                    Gott, der Ewigen Wahrheit. Wer ihre Süße kostet, wird es
                    verschmähen, sie gegen alle Herrschaft der Erde und des
                    Himmels zu tauschen.
                </NormalQuote>
                <NormalQuote reference="Bahá’u’lláh, Ährenlese, 27:2">
                    Die Wirklichkeit des Menschen jedoch hat Er zum Brennpunkt
                    für das Strahlen aller Seiner Namen und Attribute und zum
                    Spiegel Seines eigenen Selbstes erkoren. Von allem
                    Erschaffenen ist allein der Mensch zu einer so großen Gunst,
                    einer so dauerhaften Gabe auserwählt.
                </NormalQuote>
                <NormalQuote reference="‘Abdu’l-Bahá, Das Geheimnis göttlicher Kultur">
                    Dafür, daß machtvolle Gesetze niedergelegt, hohe Grundsätze
                    aufgestellt und Einrichtungen geschaffen werden, die sich
                    mit jedem Gesichtspunkt der Zivilisation befassen, ist das
                    Glück der Menschen der oberste Zweck, der tiefste Grund.
                    Dieses Glück der Menschen jedoch besteht ausschließlich
                    darin, der Schwelle Gottes, des Allmächtigen, näher zu
                    kommen und den Frieden und die Wohlfahrt jedes einzelnen
                    Angehörigen des Menschengeschlechts, sei er hoch oder
                    niedrig, zu sichern; und die besten Mittel, dieses zweifache
                    Ziel zu erreichen, sind die hervorragenden Tugenden, die der
                    Menschenwelt, verliehen worden sind.
                </NormalQuote>
                <NormalQuote reference="‘Abdu’l-Bahá, Ansprachen in Paris">
                    Bedenket, was den Menschen von den übrigen erschaffenen
                    Wesen unterscheidet und ihn zu einem besonderen Geschöpf
                    macht. Ist es nicht seine Urteilsfähigkeit, seine
                    Intelligenz […]?
                </NormalQuote>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default DieNaturDesMenschenZitate;
