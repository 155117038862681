import React from 'react';
import { Link } from 'gatsby';

const Breadcrumbs = ({
    greatgrandparent,
    grandparent,
    parent,
    currentItem,
}) => (
    <nav className="breadcrumbs">
        <ul>
            <li className="home">
                <span>
                    <Link
                        title="Zu Bahá&#039;í in Deutschland."
                        to="/"
                        className="home"
                    >
                        <span>Home</span>
                    </Link>
                </span>
            </li>
            {greatgrandparent ? (
                <li>
                    <span>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: greatgrandparent,
                            }}
                        />
                    </span>
                </li>
            ) : (
                ''
            )}
            {grandparent ? (
                <li>
                    <span>
                        <span
                            dangerouslySetInnerHTML={{ __html: grandparent }}
                        />
                    </span>
                </li>
            ) : (
                ''
            )}
            {parent ? (
                <li>
                    <span>
                        <span dangerouslySetInnerHTML={{ __html: parent }} />
                    </span>
                </li>
            ) : (
                ''
            )}
            <li className="current-item">
                <span>
                    <span dangerouslySetInnerHTML={{ __html: currentItem }} />
                </span>
            </li>
        </ul>
    </nav>
);

export default Breadcrumbs;
